import { mergeAttributes, Node } from '@tiptap/core';
import { BlockEditorTypesEnum } from '../../../block-editor/block-editor';

export const Warning = (i18n: { defaultTitle: string }) =>
  Node.create({
    name: BlockEditorTypesEnum.WARNING,
    group: 'block',

    content: 'heading paragraph+',

    addOptions() {
      return {
        HTMLAttributes: {
          class: 'font-lexend bg-warning-03 my-4 flex items-start gap-6 rounded-xl p-6',
        },
      };
    },

    renderHTML({ HTMLAttributes }) {
      return [
        'div',
        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
        [
          'i',
          {
            class: 'icon icon--warning text-3xl mt-2 text-warning-01',
            contentEditable: false,
          },
        ],
        ['div', 0],
      ];
    },

    addKeyboardShortcuts() {
      return {
        Enter: ({ editor }) => {
          const { state } = editor;
          const { selection } = state;
          const { $from, empty } = selection;

          if (!empty || $from.parent.type !== this.type) {
            return false;
          }

          const isAtEnd = $from.parentOffset === $from.parent.nodeSize - 2;
          const endsWithDoubleNewline = $from.parent.textContent.endsWith('\n\n');

          if (!isAtEnd || !endsWithDoubleNewline) {
            return false;
          }

          return editor
            .chain()
            .command(({ tr }) => {
              tr.delete($from.pos - 2, $from.pos);

              return true;
            })
            .exitCode()
            .run();
        },
      };
    },

    addCommands() {
      return {
        insertWarning:
          () =>
          ({ tr, dispatch, commands }) => {
            if (dispatch) {
              commands.insertContent({
                type: this.name,
                content: [
                  {
                    type: 'heading',
                    attrs: { level: 3 },
                    content: [{ type: 'text', text: i18n.defaultTitle }],
                  },

                  { type: 'paragraph', content: [] },
                ],
              });
            }

            return true;
          },
      };
    },
  });

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    warning: {
      /**
       * Comments will be added to the autocomplete.
       */
      insertWarning: () => ReturnType;
    };
  }
}
