import { BubbleMenu as BaseBubbleMenu, useEditorState } from '@tiptap/react';
import { useCallback } from 'react';
import { sticky } from 'tippy.js';
import { v4 as uuid } from 'uuid';

import { ColumnLayout } from '../columns';
import getRenderContainer from '../../../utils/getRenderContainer';
import { MenuProps } from '../../../menus/types';
import { Toolbar } from '../../../ui/Toolbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faColumns, faSidebar, faSidebarFlip } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';

export const ColumnsMenu = ({ editor, appendTo }: MenuProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.tiptap.columns-menu' });

  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, 'columns');
    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);

    return rect;
  }, [editor]);

  const shouldShow = useCallback(() => {
    const isColumns = editor.isActive('columns');
    return isColumns;
  }, [editor]);

  const onColumnLeft = useCallback(() => {
    editor.chain().focus().setLayout(ColumnLayout.SidebarLeft).run();
  }, [editor]);

  const onColumnRight = useCallback(() => {
    editor.chain().focus().setLayout(ColumnLayout.SidebarRight).run();
  }, [editor]);

  const onColumnTwo = useCallback(() => {
    editor.chain().focus().setLayout(ColumnLayout.TwoColumn).run();
  }, [editor]);
  const { isColumnLeft, isColumnRight, isColumnTwo } = useEditorState({
    editor,
    selector: (ctx) => {
      return {
        isColumnLeft: ctx.editor.isActive('columns', { layout: ColumnLayout.SidebarLeft }),
        isColumnRight: ctx.editor.isActive('columns', { layout: ColumnLayout.SidebarRight }),
        isColumnTwo: ctx.editor.isActive('columns', { layout: ColumnLayout.TwoColumn }),
      };
    },
  });

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey={`columnsMenu-${uuid()}`}
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        appendTo: () => appendTo?.current,
        plugins: [sticky],
        sticky: 'popper',
      }}
    >
      <Toolbar.Wrapper>
        <Toolbar.Button
          tooltip={t('sidebar-left')}
          active={isColumnLeft}
          onClick={onColumnLeft}
        >
          <FontAwesomeIcon icon={faSidebar} />
        </Toolbar.Button>
        <Toolbar.Button
          tooltip={t('two-columns')}
          active={isColumnTwo}
          onClick={onColumnTwo}
        >
          <FontAwesomeIcon icon={faColumns} />
        </Toolbar.Button>
        <Toolbar.Button
          tooltip={t('sidebar-right')}
          active={isColumnRight}
          onClick={onColumnRight}
        >
          <FontAwesomeIcon icon={faSidebarFlip} />
        </Toolbar.Button>
      </Toolbar.Wrapper>
    </BaseBubbleMenu>
  );
};

export default ColumnsMenu;
