import { useCallback } from 'react';
import * as Dropdown from '@radix-ui/react-dropdown-menu';
import { Toolbar } from '../../Toolbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHighlighter } from '@fortawesome/pro-light-svg-icons';
import { Surface } from '../../Surface';
import { DropdownButton } from '../../Dropdown';
import { useTranslation } from 'react-i18next';

const COLORS = ['green', 'blue', 'red', 'orange', 'yellow'] as const;
type AvailableColors = (typeof COLORS)[number];
const colorValues: Record<AvailableColors, string> = {
  green: 'rgb(209 250 229)',
  blue: 'rgb(217 226 252)',
  red: 'rgb(254 226 226)',
  orange: 'rgb(255 237 213)',
  yellow: 'rgb(254 243 199)',
};

type Props = {
  activeColor: string;
  changeColorCommand: (color: string) => void;
  clearColorCommand: () => void;
};

export const BackgroundColorList = ({
  activeColor,
  changeColorCommand,
  clearColorCommand,
}: Props) => {
  const { t: tColors } = useTranslation('translation', { keyPrefix: 'components.tiptap.colors' });
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.tiptap.background-color-list',
  });

  const onSelectColor = useCallback(
    (color: AvailableColors) => {
      changeColorCommand(colorValues[color]);
    },
    [changeColorCommand],
  );

  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild>
        <Toolbar.Button active={(activeColor?.length ?? 0) > 0}>
          <FontAwesomeIcon
            icon={faHighlighter}
            className={'mr-1'}
          />
        </Toolbar.Button>
      </Dropdown.Trigger>
      <Dropdown.Content asChild>
        <Surface className="p-2 space-y-1 min-w-[10rem]">
          <Dropdown.Item
            onClick={clearColorCommand}
            key="clear"
          >
            <DropdownButton>{t('clear')}</DropdownButton>
          </Dropdown.Item>
          {COLORS.map((color) => (
            <Dropdown.Item
              onClick={() => onSelectColor(color)}
              key={color}
            >
              <DropdownButton isActive={activeColor === color}>
                <span className={'space-x-2'}>
                  <span
                    style={{ backgroundColor: colorValues[color] }}
                    className={`border rounded-md text-center px-2 py-1 text-sm]`}
                  >
                    A
                  </span>
                  <span style={{ backgroundColor: colorValues[color] }}>{tColors(color)}</span>
                </span>
              </DropdownButton>
            </Dropdown.Item>
          ))}
        </Surface>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
