import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { SpinnerLoader } from '../../../loaders';
import { Heading4 } from '../../../heading';
import { PrimaryButton, SecondaryButton } from '../../../buttons';

export const AILoaderComponent = ({ editor, node }: NodeViewProps) => {
  const suggestedText = node.attrs['suggestedText'];
  const uuid = node.attrs['uuid'];
  const acceptString = node.attrs['acceptString'];
  const rejectString = node.attrs['rejectString'];
  const suggestionString = node.attrs['suggestionString'];

  return (
    <NodeViewWrapper>
      <div className="relative border border-surface-02-hover space-y-2 rounded-lg">
        <div
          data-uuid={uuid}
          className="px-4 pt-4 "
        >
          <NodeViewContent />
        </div>

        <div className="bg-surface-02 p-4 space-y-4 w-full shadow-lg outline outline-primary-01 rounded-lg">
          <div className="space-y-2">
            <Heading4>{suggestionString}</Heading4>
            <div className="min-h-14 flex flex-col ">
              {suggestedText.length > 0 ? (
                <span className="whitespace-pre-wrap">{suggestedText} </span>
              ) : (
                <div className="w-full h-13 flex justify-center items-center">
                  <SpinnerLoader
                    color="blue"
                    className="block text-[5px]"
                  />
                </div>
              )}
            </div>
          </div>

          <div className="flex justify-end items-center space-x-2">
            <PrimaryButton
              label={acceptString}
              display="inline"
              enabled={suggestedText.length > 0}
              onClick={() => editor.chain().acceptSuggestion(uuid).focus().run()}
            ></PrimaryButton>
            <SecondaryButton
              display="inline"
              theme="red"
              buttonStyle="small"
              label={rejectString}
              enabled={suggestedText.length > 0}
              onClick={() => editor.chain().rejectSuggestion(uuid).focus().run()}
            ></SecondaryButton>
          </div>
        </div>
      </div>
    </NodeViewWrapper>
  );
};
