import {
  defaultGraphqlClient,
  navigation,
  useMediaQuery,
  VoidFunc,
} from '@stellar-lms-frontend/common-utils';
import { LearningActivityStep } from '@stellar-lms-frontend/lms-api';
import { JSONContent } from '@tiptap/core';
import {
  CrossIcon,
  DiscussIcon,
  HeaderImage,
  RoundedIconButton,
  ScrollContainer,
  BlockEditorV3,
} from '@stellar-lms-frontend/ui-components';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Discussion } from '..';
import { DiscussionViewProps } from '../discussion';
import { useTranslation } from 'react-i18next';
import { useCurrentCompany } from '@stellar-lms-frontend/lms-graphql';

const NAVIGATION_KEY = 'block-step';

export type BlockStepViewProps = {
  companyId: string;
  step: LearningActivityStep;
  discussionAllowed: boolean;
  blocks?: JSONContent; //EditorV2.CustomPartialBlock[];
  actions?: {
    openSupport: VoidFunc | undefined;
    discussion?: DiscussionViewProps['actions'];
  };
  documentsEnabled?: boolean;
};

export const BlockStepView = ({
  companyId,
  blocks,
  step,
  discussionAllowed = true,
  actions,
  documentsEnabled,
}: BlockStepViewProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'discussion' });

  const { learningActivityId, stepId } = useParams(); // TODO move?
  const isDesktop = useMediaQuery('(min-width: 1028px)');
  const [showDiscussion, setShowDiscussion] = useState(false);
  const [searchParams] = useSearchParams();

  // TODO: move discussion logic to separated component to re-use on block and iframe step
  useEffect(() => {
    if (searchParams.get('comment')) {
      setShowDiscussion(true);
    }
  }, [searchParams, setShowDiscussion]);

  useEffect(() => {
    if (!isDesktop) {
      if (showDiscussion && actions?.discussion) {
        navigation.setLeftPrimary(NAVIGATION_KEY, {
          leftIcon: <CrossIcon className="text-text-01" />,
          action: () => setShowDiscussion(!showDiscussion),
        });
        navigation.setTitle(NAVIGATION_KEY, t('discussion-title'));
        navigation.setRightPrimary(NAVIGATION_KEY, { action: () => null }); // dummy to hide learning activity related button
      } else {
        navigation.clearLevel(NAVIGATION_KEY);
      }
    } else {
      discussionAllowed &&
        actions?.discussion &&
        navigation.setDiscussion(NAVIGATION_KEY, {
          label: showDiscussion ? t('hide-discuss-button') : t('discuss-button'),
          leftIcon: <DiscussIcon className="text-text-01" />,
          action: () => setShowDiscussion(!showDiscussion),
        });
    }

    return () => navigation.clearLevel(NAVIGATION_KEY);
  }, [showDiscussion, isDesktop, t, discussionAllowed, actions?.discussion]);

  const renderFloatingDiscussionButton = () => (
    <div className="absolute bottom-8 right-4 rounded-full lg:hidden">
      <RoundedIconButton
        htmlId="discuss-button-mobile"
        size="large"
        onClick={() => setShowDiscussion(true)}
        icon={<DiscussIcon className="text-primary-01" />}
      />
    </div>
  );

  const {
    query: { data: company },
  } = useCurrentCompany(defaultGraphqlClient);

  if (!company) {
    return null;
  }

  return (
    <>
      <ScrollContainer scrollOnDesktop={true}>
        <HeaderImage
          isEditing={false}
          companyId={company.id}
          value={step.headerImage ?? undefined}
        />
        {!showDiscussion && discussionAllowed && renderFloatingDiscussionButton()}
        <div
          className={`${
            showDiscussion && !isDesktop ? 'hidden' : 'block'
          } mt-3 h-full items-center space-y-4 px-4 xl:min-w-[500px]`}
        >
          <BlockEditorV3.BlockEditor
            id="content-step-block-editor"
            data={blocks}
            isReadOnly
            onDiscussClick={() => setShowDiscussion(true)}
            companyId={company.id}
            documentsEnabled={documentsEnabled}
          />
        </div>
      </ScrollContainer>
      {showDiscussion && actions?.discussion && learningActivityId && stepId && (
        <Discussion.DiscussionView
          companyId={companyId}
          learningActivityId={learningActivityId}
          stepId={stepId}
          actions={actions.discussion}
        />
      )}
    </>
  );
};

export default BlockStepView;
