import * as Dropdown from '@radix-ui/react-dropdown-menu';
import { useCallback } from 'react';
import { Toolbar } from '../../../ui/Toolbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeftToLine,
  faArrowRightToLine,
  faChevronDown,
  faChevronRight,
  faEraser,
  faLightbulb,
  faMicrophone,
  faSparkles,
} from '@fortawesome/pro-light-svg-icons';
import { Surface } from '../../../ui/Surface';
import { DropdownButton } from '../../../ui/Dropdown';
import { AiTone, AiToneOption } from '../../../types';
import { useTranslation } from 'react-i18next';

export const tones: AiToneOption[] = [
  { name: 'academic', value: 'academic' },
  { name: 'business', value: 'business' },
  { name: 'casual', value: 'casual' },
  { name: 'conversational', value: 'conversational' },
  { name: 'emotional', value: 'emotional' },
  { name: 'humorous', value: 'humorous' },
  { name: 'informative', value: 'informative' },
  { name: 'inspirational', value: 'inspirational' },
  { name: 'objective', value: 'objective' },
  { name: 'persuasive', value: 'persuasive' },
  { name: 'poetic', value: 'poetic' },
];

export type AIDropdownProps = {
  onSimplify: () => void;
  onFixSpelling: () => void;
  onMakeShorter: () => void;
  onMakeLonger: () => void;
  // onSummarize: () => void;
  onTone: (tone: AiTone) => void;
};

export const AIDropdown = ({
  onFixSpelling,
  onMakeLonger,
  onMakeShorter,
  onSimplify,
  // onSummarize,
  onTone,
  // onTranslate,
}: AIDropdownProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.tiptap.extensions.ai-tools',
  });
  const { t: tTones } = useTranslation('translation', {
    keyPrefix: 'components.tiptap.extensions.ai-tools.tone.tones',
  });
  const handleTone = useCallback((tone: AiTone) => () => onTone(tone), [onTone]);
  // const handleTranslate = useCallback(
  //   (language: Language) => () => onTranslate(language),
  //   [onTranslate],
  // );

  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild>
        <Toolbar.Button
          className="text-purple-500 hover:text-purple-600 active:text-purple-600 dark:text-purple-400 dark:hover:text-purple-300 dark:active:text-purple-400"
          activeClassname="text-purple-600 hover:text-purple-600 dark:text-purple-400 dark:hover:text-purple-200"
        >
          <FontAwesomeIcon
            icon={faSparkles}
            className="mr-1"
          />
          {t('dropdown-title')}
          <FontAwesomeIcon icon={faChevronDown} />
        </Toolbar.Button>
      </Dropdown.Trigger>
      <Dropdown.Content asChild>
        <Surface className="p-2 min-w-[10rem]">
          <Dropdown.Item onClick={onSimplify}>
            <DropdownButton>
              <FontAwesomeIcon
                icon={faLightbulb}
                fixedWidth
              />
              {t('simplify-title')}
            </DropdownButton>
          </Dropdown.Item>
          <Dropdown.Item onClick={onFixSpelling}>
            <DropdownButton>
              <FontAwesomeIcon
                icon={faEraser}
                fixedWidth
              />
              {t('fix-spelling-title')}
            </DropdownButton>
          </Dropdown.Item>
          <Dropdown.Item onClick={onMakeShorter}>
            <DropdownButton>
              <FontAwesomeIcon
                icon={faArrowLeftToLine}
                fixedWidth
              />
              {t('make-shorter-title')}
            </DropdownButton>
          </Dropdown.Item>
          <Dropdown.Item onClick={onMakeLonger}>
            <DropdownButton>
              <FontAwesomeIcon
                icon={faArrowRightToLine}
                fixedWidth
              />
              {t('make-longer-title')}
            </DropdownButton>
          </Dropdown.Item>
          <Dropdown.Sub>
            <Dropdown.SubTrigger>
              <DropdownButton>
                <FontAwesomeIcon
                  icon={faMicrophone}
                  fixedWidth
                  className="shrink-0"
                />
                <span className="grow">{t('tone.change-tone-title')}</span>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  fixedWidth
                  className="shrink-0"
                />
              </DropdownButton>
            </Dropdown.SubTrigger>
            <Dropdown.SubContent>
              <Surface className="flex flex-col min-w-[15rem] p-2 max-h-[20rem] overflow-auto">
                {tones.map((tone) => (
                  <Dropdown.Item
                    onClick={handleTone(tone.value)}
                    key={tone.value}
                  >
                    <DropdownButton>{tTones(tone.value)}</DropdownButton>
                  </Dropdown.Item>
                ))}
              </Surface>
            </Dropdown.SubContent>
          </Dropdown.Sub>
          {/* <Dropdown.Item onClick={onSummarize}>
            <DropdownButton>
              <FontAwesomeIcon icon={faEllipsis} />
              Summarize
            </DropdownButton>
          </Dropdown.Item> */}
        </Surface>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
