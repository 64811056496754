import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import { AILoaderComponent } from './ai-loader-component';
import { v4 as uuidv4 } from 'uuid';
import { Node as PMNode } from 'prosemirror-model';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    aiLoader: {
      setAILoader: (originalText: string, uuid: string) => ReturnType;
      updateAILoaderContent: (content: string, uuid: string) => ReturnType;
      acceptSuggestion: (uuid: string) => ReturnType;
      rejectSuggestion: (uuid: string) => ReturnType;
    };
  }
}

type I18N = {
  accept: string;
  suggestion: string;
  reject: string;
};

export const AILoader = (i18n: I18N) =>
  Node.create({
    name: 'aiLoader',

    isolating: true,

    defining: true,

    group: 'block',
    content: 'block*',

    draggable: false,

    selectable: false,

    inline: false,

    parseHTML() {
      return [
        {
          tag: `div[data-type="${this.name}"]`,
        },
      ];
    },

    renderHTML() {
      return ['div', { 'data-type': this.name }, 0];
    },

    addAttributes() {
      return {
        uuid: {
          default: uuidv4(),
        },
        originalText: {
          default: '',
        },
        suggestedText: {
          default: '',
        },
        acceptString: {
          default: i18n.accept,
          rendered: false,
        },
        suggestionString: {
          default: i18n.suggestion,
          rendered: false,
        },
        rejectString: {
          default: i18n.reject,
          rendered: false,
        },
      };
    },

    addCommands() {
      return {
        setAILoader:
          (originalText, uuid) =>
          ({ commands, tr }) => {
            commands.wrapIn(this.name, { uuid, originalText });
            return true;
          },

        updateAILoaderContent:
          (content, uuid) =>
          ({ editor }) => {
            const nodes = editor.$nodes(this.name, { uuid });

            if (nodes && nodes.length > 0) {
              const node = nodes[0];

              const tr = editor.state.tr;

              const newNode = this.type.create(
                { ...node.node.attrs, suggestedText: content },
                node.node.content,
                node.node.marks,
              );

              tr.replaceWith(node.pos - 1, node.pos - 1 + node.node.nodeSize, newNode);
              editor.view.dispatch(tr);
            }

            return true;
          },

        acceptSuggestion:
          (uuid) =>
          ({ editor, tr }) => {
            const nodes = editor.$nodes(this.name, { uuid });
            if (nodes && nodes.length > 0) {
              const node = nodes[0];
              const suggestion = node.attributes['suggestedText'];
              const pos = node.pos - 1;

              const createdNodes: PMNode[] = [];
              suggestion.split('\n').forEach((paragraph: string) => {
                if (paragraph.trim() !== '') {
                  const newNode = editor.schema.nodes['paragraph'].create(
                    null,
                    editor.schema.text(paragraph),
                  );
                  createdNodes.push(newNode);
                }
              });

              tr.replaceWith(pos, pos + node.node.nodeSize, createdNodes);

              return true;
            }

            console.error('No matching node found for UUID', uuid);
            return false;
          },

        rejectSuggestion:
          (uuid) =>
          ({ editor, tr }) => {
            const nodes = editor.$nodes(this.name, { uuid });
            if (nodes && nodes.length > 0) {
              const node = nodes[0];
              const pos = node.pos - 1;
              tr.replaceWith(pos, pos + node.node.nodeSize, node.content);

              return true;
            }

            console.error('No matching node found for UUID', uuid);
            return false;
          },
      };
    },

    addNodeView() {
      return ReactNodeViewRenderer(AILoaderComponent);
    },
  });

export default AILoader;
