import { useCallback } from 'react';
import * as Dropdown from '@radix-ui/react-dropdown-menu';
import { Toolbar } from '../../Toolbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette } from '@fortawesome/pro-light-svg-icons';
import { Surface } from '../../Surface';
import { DropdownButton } from '../../Dropdown';
import { useTranslation } from 'react-i18next';

const COLORS = ['blue', 'green', 'red', 'orange'] as const;
type AvailableColors = (typeof COLORS)[number];
const colorValues: Record<AvailableColors, string> = {
  green: 'rgb(6 95 70)',
  blue: 'rgb(37 81 218)',
  red: 'rgb(239 68 68)',
  orange: 'rgb(249 115 22)',
};

type Props = {
  activeColor: string;
  changeColorCommand: (color: string) => void;
  clearColorCommand: () => void;
};

export const ColorList = ({ activeColor, changeColorCommand, clearColorCommand }: Props) => {
  const { t: tColors } = useTranslation('translation', { keyPrefix: 'components.tiptap.colors' });
  const { t } = useTranslation('translation', { keyPrefix: 'components.tiptap.color-list' });

  const onSelectColor = useCallback(
    (color: AvailableColors) => {
      changeColorCommand(colorValues[color]);
    },
    [changeColorCommand],
  );

  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild>
        <Toolbar.Button active={(activeColor?.length ?? 0) > 0}>
          <FontAwesomeIcon
            icon={faPalette}
            className={'mr-1'}
          />
        </Toolbar.Button>
      </Dropdown.Trigger>
      <Dropdown.Content asChild>
        <Surface className="p-2 space-y-1 min-w-[10rem]">
          <Dropdown.Item onClick={clearColorCommand}>
            <DropdownButton>{t('clear')}</DropdownButton>
          </Dropdown.Item>
          {COLORS.map((color) => (
            <Dropdown.Item
              onClick={() => onSelectColor(color)}
              key={color}
            >
              <DropdownButton isActive={activeColor === color}>
                <span
                  style={{ color: colorValues[color] }}
                  className={'space-x-2'}
                >
                  <span
                    style={{ borderColor: colorValues[color] }}
                    className={`border rounded-md text-center px-2 py-1 text-sm]`}
                  >
                    A
                  </span>
                  <span>{tColors(color)}</span>
                </span>
              </DropdownButton>
            </Dropdown.Item>
          ))}
        </Surface>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
