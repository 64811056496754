import React, { Dispatch, SetStateAction } from 'react';

export const LearningActivityContext = React.createContext<{
  saveFunc?: () => Promise<void>;
  setSaveFunc?: Dispatch<SetStateAction<(() => Promise<void>) | undefined>>;
  undoRedoButtons?: {
    undo: () => void;
    redo: () => void;
  };
  setUndoRedoButtons?: Dispatch<
    SetStateAction<
      | {
          undo: () => void;
          redo: () => void;
        }
      | undefined
    >
  >;
  isDirty: boolean;
  setIsDirty?: Dispatch<SetStateAction<boolean>>;
  isValid: boolean;
  setIsValid?: Dispatch<SetStateAction<boolean>>;
  user?: { id?: string; viewedTips: string[] };
}>({ isDirty: false, isValid: false });
