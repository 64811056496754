import { ExtendedRegExpMatchArray, mergeAttributes, Node, NodePos } from '@tiptap/core';

import {
  calculateRemoteId,
  createPasteRules,
  getAttributes,
  getEmbedUrl,
  SupportedServices,
} from './embed-utils';
import { BlockEditorTypesEnum } from '../../../block-editor/block-editor';

export interface EmbedOptions {
  src: string;
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    embed: {
      insertEmbed: (
        pos: number,
        nodeSize: number,
        match: ExtendedRegExpMatchArray,
        key: string,
      ) => ReturnType;
    };
  }
}

export const Embed = Node.create<EmbedOptions>({
  name: BlockEditorTypesEnum.EMBED,
  draggable: true,
  group: 'block',
  content: 'paragraph',

  addOptions() {
    return {
      src: '',
      HTMLAttributes: {
        class: 'my-2',
        contenteditable: false,
      },
    };
  },

  addAttributes() {
    return {
      src: {
        default: null,
      },
      embedType: {
        default: null,
      },
      remoteId: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-embed] iframe',
      },
    ];
  },

  addPasteRules() {
    return createPasteRules(this.type);
  },

  renderHTML({ HTMLAttributes }) {
    const type = HTMLAttributes['embedType'];
    const embedUrl = getEmbedUrl(type, HTMLAttributes['remoteId']);
    const iframeHTMLAttributes = getAttributes(type);

    HTMLAttributes['src'] = embedUrl;

    return [
      'div',
      { 'data-embed': '', class: 'mb-4' },
      [
        'iframe',
        mergeAttributes(this.options.HTMLAttributes, iframeHTMLAttributes, HTMLAttributes),
      ],
      ['div', { class: 'border-1 border-border-02 rounded-sm p-1' }, 0],
    ];
  },

  addCommands() {
    return {
      insertEmbed:
        (nodePos: number, nodeSize: number, match: ExtendedRegExpMatchArray, key: string) =>
        ({ commands, dispatch, tr, editor }) => {
          if (dispatch) {
            tr.replaceWith(
              nodePos,
              nodePos + nodeSize,
              editor.schema.nodes['embed'].create(
                {
                  src: match.input,
                  remoteId: calculateRemoteId([...match.values()], key as SupportedServices),
                  embedType: key,
                },
                editor.schema.nodes['paragraph'].create(null, null),
              ),
            );
            editor.view.dispatch(tr);
          }
          return true;
        },
    };
  },
});
