import { ReactNode } from 'react';
import { cn } from '@stellar-lms-frontend/common-utils';

type ButtonSize = 'default' | 'large' | 'small' | 'normal-button';
type RoundedIconButtonProps = {
  htmlId: string;
  enabled?: boolean;
  onClick?: () => void;
  icon?: ReactNode;
  size?: ButtonSize;
  buttonStyle?: 'rounded' | 'normal';
  className?: string;
};

export const RoundedIconButton = ({
  htmlId,
  onClick,
  icon,
  size = 'default',
  className = '',
  buttonStyle = 'rounded',
}: RoundedIconButtonProps) => {
  const sizeClassName = getSizeClassName(size);

  return (
    <div
      id={htmlId}
      role="button"
      className={cn(
        'border-1 border-border-02 rounded-xl flex items-center justify-center bg-white',
        sizeClassName,
        className,
        buttonStyle === 'rounded' && 'rounded-full shadow-lg',
      )}
      onClick={onClick}
    >
      {icon}
    </div>
  );
};

const getSizeClassName = (size: ButtonSize) => {
  let className = '';
  switch (size) {
    case 'small':
      className = 'w-5 h-5';
      break;
    case 'default':
      className = 'w-10 h-10';
      break;
    case 'normal-button':
      className = 'w-12 h-12';
      break;
    case 'large':
      className = 'w-13 h-13';
      break;
  }
  return className;
};
