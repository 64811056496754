import { Editor, NodeViewWrapper } from '@tiptap/react';
import { useCallback } from 'react';

import { DocumentUploader } from './document-uploader';
import { Node } from '@tiptap/pm/model';
import { DocumentNotSupportedComponent } from './document-not-supported-component';

export const DocumentUpload = ({
  getPos,
  editor,
  node,
}: {
  getPos: () => number;
  editor: Editor;
  node: Node;
}) => {
  const companyId = node.attrs['companyId'];
  const documentsEnabled = node.attrs['documentsEnabled'];

  const onUpload = useCallback(
    (url: string, fileId: string, fileName: string, mimeType: string) => {
      if (url) {
        editor
          .chain()
          .setDocumentBlock({ src: url, fileId: fileId, fileName: fileName, mimeType: mimeType })
          .deleteRange({ from: getPos(), to: getPos() })
          .focus()
          .run();
      }
    },
    [getPos, editor],
  );

  return (
    <NodeViewWrapper>
      <div
        className="p-0 m-0"
        data-drag-handle
      >
        {documentsEnabled ? (
          <DocumentUploader
            onUpload={onUpload}
            companyId={companyId}
          />
        ) : (
          <DocumentNotSupportedComponent />
        )}
      </div>
    </NodeViewWrapper>
  );
};

export default DocumentUpload;
