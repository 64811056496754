import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { DiscussionComponent } from './discussion-component';
import { BlockEditorTypesEnum } from '../../../block-editor/block-editor';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    discussionPrompt: {
      openDiscussionPrompt: () => ReturnType;
      insertDiscussionPrompt: () => ReturnType;
    };
  }
}

export const DiscussionPrompt = (i18n: { defaultTitle: string }, onOpenDiscussion: () => void) =>
  Node.create({
    name: BlockEditorTypesEnum.DISCUSSION_PROMPT,
    group: 'block',

    content: 'heading paragraph+',

    addOptions() {
      return {
        HTMLAttributes: {
          class: 'font-lexend bg-surface-03 my-4 flex items-start gap-6 rounded-xl p-6',
        },
      };
    },

    addAttributes() {
      return {
        discussionOpen: {
          default: false,
        },
      };
    },

    addNodeView() {
      return ReactNodeViewRenderer(DiscussionComponent);
    },

    addCommands() {
      return {
        openDiscussionPrompt:
          () =>
          ({ commands }) => {
            onOpenDiscussion();
            return commands.focus();
          },
        insertDiscussionPrompt:
          () =>
          ({ tr, dispatch, commands }) => {
            if (dispatch) {
              commands.insertContent({
                type: BlockEditorTypesEnum.DISCUSSION_PROMPT,
                content: [
                  {
                    type: 'heading',
                    attrs: { level: 3 },
                    content: [{ type: 'text', text: i18n.defaultTitle }],
                  },
                  {
                    type: 'paragraph',
                    content: [],
                  },
                ],
              });
            }

            return true;
          },
      };
    },

    renderHTML({ HTMLAttributes }) {
      return [
        'discussion-prompt-node-view',
        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
        0,
      ];
    },
  });

export default DiscussionPrompt;
