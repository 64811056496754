import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import { SupportedServices } from '../embed/embed-utils';
import { EmbedLoaderComponent } from './embed-loader-component';

type I18N = {
  errorLabel: string;
  placeholderLabel: (type: string) => string;
  addButtonLabel: string;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    embedLoader: {
      openEmbedLoader: (embedType: SupportedServices) => ReturnType;
    };
  }
}

export const EmbedLoader = (i18n: I18N) =>
  Node.create({
    name: 'embedLoader',
    group: 'block',

    isolating: true,
    defining: true,

    addAttributes() {
      return {
        embedType: {
          default: null,
          rendered: false,
        },
        errorLabel: {
          default: i18n.errorLabel,
          rendered: false,
        },
        addButtonLabel: {
          default: i18n.addButtonLabel,
          rendered: false,
        },
        placeholderLabel: {
          default: i18n.placeholderLabel('youtube'),
          rendered: false,
        },
      };
    },

    parseHTML() {
      return [
        {
          tag: `div[data-type="${this.name}"]`,
        },
      ];
    },

    renderHTML() {
      return ['div', { 'data-type': this.name }];
    },
    addNodeView() {
      return ReactNodeViewRenderer(EmbedLoaderComponent);
    },

    addCommands() {
      return {
        openEmbedLoader:
          (embedType) =>
          ({ commands }) =>
            commands.insertContent(
              `<div data-type="${this.name}" embedType="${embedType}" placeholderLabel="${i18n.placeholderLabel(embedType)}"></div>`,
            ),
      };
    },
  });
