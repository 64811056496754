import { Node } from '@tiptap/core';
import { v4 as uuidv4 } from 'uuid';
import { AITextActions } from '../../block-editor';
import { AiTone } from '../../types';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    aiFunctions: {
      aiSimplify: () => ReturnType;
      aiFixSpellingAndGrammar: () => ReturnType;
      aiExtend: () => ReturnType;
      aiShorten: () => ReturnType;
      aiSummarize: () => ReturnType;
      aiAdjustTone: (tone: AiTone) => ReturnType;
    };
  }
}

export const AIFunctions = (actions: AITextActions) => {
  return Node.create({
    name: 'aiFunctions',

    addCommands() {
      return {
        aiSimplify:
          () =>
          ({ commands, editor, chain, tr }) => {
            const { $from, $to } = tr.selection;
            const content = editor.state.doc.textBetween($from.pos, $to.pos, '\n').toString();

            const uuid = uuidv4();

            actions.aiSimplify(content).then((simplifiedContent) => {
              commands.updateAILoaderContent(simplifiedContent, uuid);
            });
            return chain().setAILoader(content, uuid).focus().run();
          },
        aiAdjustTone:
          (tone: AiTone) =>
          ({ commands, editor, chain, tr }) => {
            const { $from, $to } = tr.selection;
            const content = editor.state.doc.textBetween($from.pos, $to.pos).toString();

            const uuid = uuidv4();

            actions.aiAdjustTone(content, tone).then((simplifiedContent) => {
              commands.updateAILoaderContent(simplifiedContent, uuid);
            });

            return chain().setAILoader(content, uuid).focus().run();
          },
        aiExtend:
          () =>
          ({ commands, editor, chain, tr }) => {
            const { $from, $to } = tr.selection;
            const content = editor.state.doc.textBetween($from.pos, $to.pos).toString();

            const uuid = uuidv4();

            actions.aiExtend(content).then((simplifiedContent) => {
              commands.updateAILoaderContent(simplifiedContent, uuid);
            });

            return chain().setAILoader(content, uuid).focus().run();
          },
        aiShorten:
          () =>
          ({ commands, editor, chain, tr }) => {
            const { $from, $to } = tr.selection;
            const content = editor.state.doc.textBetween($from.pos, $to.pos).toString();

            const uuid = uuidv4();

            actions.aiShorten(content).then((simplifiedContent) => {
              commands.updateAILoaderContent(simplifiedContent, uuid);
            });

            return chain().setAILoader(content, uuid).focus().run();
          },
        aiFixSpellingAndGrammar:
          () =>
          ({ commands, editor, chain, tr }) => {
            const { $from, $to } = tr.selection;
            const content = editor.state.doc.textBetween($from.pos, $to.pos).toString();

            const uuid = uuidv4();

            actions.aiFixSpellingAndGrammar(content).then((simplifiedContent) => {
              commands.updateAILoaderContent(simplifiedContent, uuid);
            });

            return chain().setAILoader(content, uuid).focus().run();
          },
      };
    },
  });
};

export default AIFunctions;
