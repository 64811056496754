import {
  faH1,
  faH2,
  faH3,
  faH4,
  faListUl,
  faListOl,
  faListCheck,
  faCode,
  faTable,
  faImage,
  faFile,
  faHorizontalRule,
  faArrowsToCircle,
  faHandPointRight,
  faExclamationTriangle,
  faMessages,
  faSuitcase,
  faColumns,
  faQuotes,
  faVideo,
  faBrain,
  faMicrophone,
  faCloud,
  faGameBoard,
  faPersonRays,
  faPeople,
  faGif,
  faChalkboard,
} from '@fortawesome/pro-light-svg-icons';
import { Group } from './types';

export type CommandI18N = {
  title: string;
  description?: string;
};

export type I18N = {
  integrationsTitle: string;
  h1: CommandI18N;
  h2: CommandI18N;
  h3: CommandI18N;
  h4: CommandI18N;
  bulletList: CommandI18N;
  numberedList: CommandI18N;
  taskList: CommandI18N;
  codeBlock: CommandI18N;
  table: CommandI18N;
  image: CommandI18N;
  document: CommandI18N;
  horizontalRule: CommandI18N;
  example: CommandI18N;
  keyTakeAways: CommandI18N;
  practicalTips: CommandI18N;
  warning: CommandI18N;
  discussion: CommandI18N;
  columns: CommandI18N;
  blockQuote: CommandI18N;
  youtube: CommandI18N;
  genially: CommandI18N;
  vimeo: CommandI18N;
  imgur: CommandI18N;
  gfycat: CommandI18N;
  outgrow: CommandI18N;
  hubspot: CommandI18N;
  nepazing: CommandI18N;
  miro: CommandI18N;
  synthesia: CommandI18N;
};

export const getGroups = (companyId: string, documentsEnabled: boolean, i18n: I18N): Group[] => [
  // {
  //   name: 'ai',
  //   title: 'AI',
  //   commands: [
  //     {
  //       name: 'aiWriter',
  //       label: 'AI Writer',
  //       icon: Sparkles,
  //       description: 'Let AI finish your thoughts',
  //       shouldBeHidden: editor => editor.isActive('columns'),
  //       action: editor => editor.chain().focus().setAiWriter().run(),
  //     },
  //     {
  //       name: 'aiImage',
  //       label: 'AI Image',
  //       icon: Sparkles,
  //       description: 'Generate an image from text',
  //       shouldBeHidden: editor => editor.isActive('columns'),
  //       action: editor => editor.chain().focus().setAiImage().run(),
  //     },
  //   ],
  // },
  {
    name: 'format',
    commands: [
      {
        name: 'image',
        label: i18n.image.title,
        icon: faImage,
        description: i18n.image.description ?? '',
        aliases: ['img'],
        action: (editor) => {
          editor.chain().focus().setImageUpload(companyId).run();
        },
      },
      {
        name: 'document',
        label: i18n.document.title,
        icon: faFile,
        description: i18n.document.description ?? '',
        aliases: ['doc'],
        paywalled: !documentsEnabled,
        action: (editor) => {
          editor.chain().focus().setDocumentUpload(companyId).run();
        },
      },
      {
        name: 'example',
        label: i18n.example.title,
        icon: faSuitcase,
        description: i18n.example.description ?? '',
        action: (editor) => {
          editor.chain().focus().insertExample().run();
        },
      },
      {
        name: 'keyTakeaways',
        label: i18n.keyTakeAways.title,
        icon: faArrowsToCircle,
        description: i18n.keyTakeAways.description ?? '',
        action: (editor) => {
          editor.chain().focus().insertKeyTakeaways().run();
        },
      },
      {
        name: 'practicalTips',
        label: i18n.practicalTips.title,
        icon: faHandPointRight,
        description: i18n.practicalTips.description ?? '',
        action: (editor) => {
          editor.chain().focus().insertPracticalTips().run();
        },
      },
      {
        name: 'warning',
        label: i18n.warning.title,
        icon: faExclamationTriangle,
        description: i18n.warning.description ?? '',
        action: (editor) => {
          editor.chain().focus().insertWarning().run();
        },
      },
      {
        name: 'discussionPrompt',
        label: i18n.discussion.title,
        icon: faMessages,
        description: i18n.discussion.description ?? '',
        action: (editor) => {
          editor.chain().focus().insertDiscussionPrompt().run();
        },
      },
      {
        name: 'codeBlock',
        label: i18n.codeBlock.title,
        icon: faCode,
        description: i18n.codeBlock.description ?? '',
        shouldBeHidden: (editor) => editor.isActive('columns'),
        action: (editor) => {
          editor.chain().focus().setCodeBlock().run();
        },
      },
      {
        name: 'table',
        label: i18n.table.title,
        icon: faTable,
        description: i18n.table.description ?? '',
        shouldBeHidden: (editor) => editor.isActive('columns'),
        action: (editor) => {
          editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: false }).run();
        },
      },
      {
        name: 'bulletList',
        label: i18n.bulletList.title,
        icon: faListUl,
        description: i18n.bulletList.description ?? '',
        aliases: ['ul'],
        action: (editor) => {
          editor.chain().focus().toggleBulletList().run();
        },
      },
      {
        name: 'numberedList',
        label: i18n.numberedList.title,
        icon: faListOl,
        description: i18n.numberedList.description ?? '',
        aliases: ['ol'],
        action: (editor) => {
          editor.chain().focus().toggleOrderedList().run();
        },
      },
      {
        name: 'taskList',
        label: i18n.taskList.title,
        icon: faListCheck,
        description: i18n.taskList.description ?? '',
        aliases: ['todo'],
        action: (editor) => {
          editor.chain().focus().toggleTaskList().run();
        },
      },
      {
        name: 'blockquote',
        label: i18n.blockQuote.title,
        icon: faQuotes,
        description: i18n.blockQuote.description ?? '',
        action: (editor) => {
          editor.chain().focus().setBlockquote().run();
        },
      },
      {
        name: 'columns',
        label: i18n.columns.title,
        icon: faColumns,
        description: i18n.columns.description ?? '',
        aliases: ['cols'],
        shouldBeHidden: (editor) => editor.isActive('columns'),
        action: (editor) => {
          editor
            .chain()
            .focus()
            .setColumns()
            .focus(editor.state.selection.head - 1)
            .run();
        },
      },
      {
        name: 'horizontalRule',
        label: i18n.horizontalRule.title,
        icon: faHorizontalRule,
        description: i18n.horizontalRule.description ?? '',
        aliases: ['hr'],
        action: (editor) => {
          editor.chain().focus().setHorizontalRule().run();
        },
      },
      {
        name: 'heading1',
        label: i18n.h1.title,
        icon: faH1,
        description: i18n.h1.description ?? '',
        aliases: ['h1'],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 1 }).run();
        },
      },
      {
        name: 'heading2',
        label: i18n.h2.title,
        icon: faH2,
        description: i18n.h2.description ?? '',
        aliases: ['h2'],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 2 }).run();
        },
      },
      {
        name: 'heading3',
        label: i18n.h3.title,
        icon: faH3,
        description: i18n.h3.description ?? '',
        aliases: ['h3'],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 3 }).run();
        },
      },
      {
        name: 'heading4',
        label: i18n.h4.title,
        icon: faH4,
        description: i18n.h4.description ?? '',
        aliases: ['h4'],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 4 }).run();
        },
      },
      // {
      //   name: 'toggleList',
      //   label: 'Toggle List',
      //   icon: faSquareChevronDown,
      //   description: 'Toggles can show and hide content',
      //   aliases: ['toggle'],
      //   action: (editor) => {
      //     editor.chain().focus().setDetails().run();
      //   },
      // },
      // {
      //   name: 'toc',
      //   label: 'Table of Contents',
      //   icon: Book,
      //   aliases: ['outline'],
      //   description: 'Insert a table of contents',
      //   shouldBeHidden: (editor) => editor.isActive('columns'),
      //   action: (editor) => {
      //     editor.chain().focus().insertTableOfContents().run();
      //   },
      // },
    ],
  },
  {
    name: 'integrations',
    title: i18n.integrationsTitle,
    commands: [
      {
        name: 'youtube',
        label: i18n.youtube.title,
        icon: faVideo,
        description: i18n.youtube.description ?? '',
        action: (editor) => {
          editor.chain().focus().openEmbedLoader('youtube').run();
        },
      },
      {
        name: 'vimeo',
        label: i18n.vimeo.title,
        icon: faVideo,
        description: i18n.vimeo.description ?? '',
        action: (editor) => {
          editor.chain().focus().openEmbedLoader('vimeo').run();
        },
      },
      {
        name: 'genially',
        label: i18n.genially.title,
        icon: faBrain,
        description: i18n.genially.description ?? '',
        action: (editor) => {
          editor.chain().focus().openEmbedLoader('genially').run();
        },
      },
      {
        name: 'synthesia',
        label: i18n.synthesia.title,
        icon: faMicrophone,
        description: i18n.synthesia.description ?? '',
        action: (editor) => {
          editor.chain().focus().openEmbedLoader('synthesia').run();
        },
      },
      {
        name: 'nepazing',
        label: i18n.nepazing.title,
        icon: faGameBoard,
        description: i18n.nepazing.description ?? '',
        action: (editor) => {
          editor.chain().focus().openEmbedLoader('nepazing').run();
        },
      },
      {
        name: 'outgrow',
        label: i18n.outgrow.title,
        icon: faPersonRays,
        description: i18n.outgrow.description ?? '',
        action: (editor) => {
          editor.chain().focus().openEmbedLoader('outgrow').run();
        },
      },
      {
        name: 'hubspot',
        label: i18n.hubspot.title,
        icon: faPeople,
        description: i18n.hubspot.description ?? '',
        action: (editor) => {
          editor.chain().focus().openEmbedLoader('hubspot').run();
        },
      },
      {
        name: 'imgur',
        label: i18n.imgur.title,
        icon: faImage,
        description: i18n.imgur.description ?? '',
        action: (editor) => {
          editor.chain().focus().openEmbedLoader('imgur').run();
        },
      },
      {
        name: 'gfycat',
        label: i18n.gfycat.title,
        icon: faGif,
        description: i18n.gfycat.description ?? '',
        action: (editor) => {
          editor.chain().focus().openEmbedLoader('gfycat').run();
        },
      },
      {
        name: 'miro',
        label: i18n.miro.title,
        icon: faChalkboard,
        description: i18n.miro.description ?? '',
        action: (editor) => {
          editor.chain().focus().openEmbedLoader('miro').run();
        },
      },
    ],
  },
];

export default getGroups;
