import { BubbleMenu as BaseBubbleMenu } from '@tiptap/react';
import React, { useCallback } from 'react';

import { isColumnGripSelected } from './utils';
import { Toolbar } from '../../../../ui/Toolbar';
import { faCaretCircleLeft, faCaretCircleRight, faTrash } from '@fortawesome/pro-light-svg-icons';
import { MenuProps, ShouldShowProps } from '../../../../menus/types';
import * as PopoverMenu from '../../../../ui/PopoverMenu';
import { useTranslation } from 'react-i18next';

export const TableColumnMenu = React.memo(({ editor, appendTo }: MenuProps): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.tiptap.table' });

  const shouldShow = useCallback(
    ({ view, state, from }: ShouldShowProps) => {
      if (!state) {
        return false;
      }

      return isColumnGripSelected({ editor, view, state, from: from || 0 });
    },
    [editor],
  );

  const onAddColumnBefore = useCallback(() => {
    editor.chain().focus().addColumnBefore().run();
  }, [editor]);

  const onAddColumnAfter = useCallback(() => {
    editor.chain().focus().addColumnAfter().run();
  }, [editor]);

  const onDeleteColumn = useCallback(() => {
    editor.chain().focus().deleteColumn().run();
  }, [editor]);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey="tableColumnMenu"
      updateDelay={0}
      tippyOptions={{
        appendTo: () => {
          return appendTo?.current;
        },
        offset: [0, 15],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
      }}
      shouldShow={shouldShow}
    >
      <Toolbar.Wrapper isVertical>
        <PopoverMenu.Item
          icon={faCaretCircleLeft}
          close={false}
          label={t('add-column-before')}
          onClick={onAddColumnBefore}
        />
        <PopoverMenu.Item
          icon={faCaretCircleRight}
          close={false}
          label={t('add-column-after')}
          onClick={onAddColumnAfter}
        />
        <PopoverMenu.Item
          icon={faTrash}
          close={false}
          label={t('delete-column')}
          onClick={onDeleteColumn}
        />
      </Toolbar.Wrapper>
    </BaseBubbleMenu>
  );
});

TableColumnMenu.displayName = 'TableColumnMenu';

export default TableColumnMenu;
