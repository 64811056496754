import * as Popover from '@radix-ui/react-popover';
import { Toolbar } from '../../../ui/Toolbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/pro-light-svg-icons';
import { LinkEditorPanel } from '../../../ui/panels';
import { useTranslation } from 'react-i18next';

export type EditLinkPopoverProps = {
  onSetLink: (link: string, openInNewTab?: boolean) => void;
};

export const EditLinkPopover = ({ onSetLink }: EditLinkPopoverProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.tiptap.text-menu' });

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <Toolbar.Button tooltip={t('set-link')}>
          <FontAwesomeIcon icon={faLink} />
        </Toolbar.Button>
      </Popover.Trigger>
      <Popover.Content>
        <LinkEditorPanel onSetLink={onSetLink} />
      </Popover.Content>
    </Popover.Root>
  );
};
