import { useTextmenuCommands } from './hooks/useTextmenuCommands';
import { useTextmenuStates } from './hooks/useTextmenuStates';
import { BubbleMenu, Editor } from '@tiptap/react';
import { memo } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { useTextmenuContentTypes } from './hooks/useTextmenuContentTypes';
import { ContentTypePicker } from './components/ContentTypePicker';
import { EditLinkPopover } from './components/EditLinkPopover';
import { Toolbar } from '../../ui/Toolbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faBold,
  faCode,
  faEllipsisV,
  faFileCode,
  faItalic,
  faStrikethrough,
  faSubscript,
  faSuperscript,
  faUnderline,
} from '@fortawesome/pro-light-svg-icons';
import { ColorList } from '../../ui/panels/color-list';
import { BackgroundColorList } from '../../ui/panels/background-color-list';
import { useTranslation } from 'react-i18next';
import { AIDropdown } from './components/ai-dropdown';

// We memorize the button so each button is not rerendered
// on every editor state change
const MemoButton = memo(Toolbar.Button);
// const MemoFontFamilyPicker = memo(FontFamilyPicker);
// const MemoFontSizePicker = memo(FontSizePicker);
const MemoContentTypePicker = memo(ContentTypePicker);
const MemoColorList = memo(ColorList);
const MemoBackgroundColorList = memo(BackgroundColorList);

export type TextMenuProps = {
  editor: Editor;
};

export const TextMenu = ({ editor }: TextMenuProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.tiptap.text-menu' });

  const commands = useTextmenuCommands(editor);
  const states = useTextmenuStates(editor);
  const blockOptions = useTextmenuContentTypes(editor);

  return (
    <BubbleMenu
      tippyOptions={{
        popperOptions: {
          placement: 'top-start',
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                boundary: 'viewport',
                padding: 8,
              },
            },
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['bottom-start', 'top-end', 'bottom-end'],
              },
            },
          ],
        },
        maxWidth: 'calc(100vw - 16px)',
      }}
      editor={editor}
      pluginKey="textMenu"
      shouldShow={states.shouldShow}
      updateDelay={100}
    >
      <Toolbar.Wrapper>
        <AIDropdown
          onFixSpelling={commands.onFixSpelling}
          onMakeLonger={commands.onMakeLonger}
          onMakeShorter={commands.onMakeShorter}
          onSimplify={commands.onSimplify}
          // onSummarize={commands.onSummarize}
          onTone={commands.onTone}
          // onTranslate={commands.onTranslate}
        />
        <Toolbar.Divider />
        <MemoContentTypePicker options={blockOptions} />
        {/* <MemoFontFamilyPicker
          onChange={commands.onSetFont}
          value={states.currentFont || ''}
        />
        <MemoFontSizePicker
          onChange={commands.onSetFontSize}
          value={states.currentSize || ''}
        /> */}
        <Toolbar.Divider />
        <MemoButton
          tooltip={t('bold')}
          tooltipShortcut={['Mod', 'B']}
          onClick={commands.onBold}
          active={states.isBold}
        >
          <FontAwesomeIcon icon={faBold} />
        </MemoButton>
        <MemoButton
          tooltip={t('italic')}
          tooltipShortcut={['Mod', 'I']}
          onClick={commands.onItalic}
          active={states.isItalic}
        >
          <FontAwesomeIcon icon={faItalic} />
        </MemoButton>
        <MemoButton
          tooltip={t('underline')}
          tooltipShortcut={['Mod', 'U']}
          onClick={commands.onUnderline}
          active={states.isUnderline}
        >
          <FontAwesomeIcon icon={faUnderline} />
        </MemoButton>
        <MemoButton
          tooltip={t('strikethrough')}
          tooltipShortcut={['Mod', 'Shift', 'S']}
          onClick={commands.onStrike}
          active={states.isStrike}
        >
          <FontAwesomeIcon icon={faStrikethrough} />
        </MemoButton>
        <MemoButton
          tooltip={t('code')}
          tooltipShortcut={['Mod', 'E']}
          onClick={commands.onCode}
          active={states.isCode}
        >
          <FontAwesomeIcon icon={faCode} />
        </MemoButton>
        <MemoButton
          tooltip={t('code-block')}
          onClick={commands.onCodeBlock}
        >
          <FontAwesomeIcon icon={faFileCode} />
        </MemoButton>
        <EditLinkPopover onSetLink={commands.onLink} />
        <MemoBackgroundColorList
          changeColorCommand={commands.onChangeHighlight}
          clearColorCommand={commands.onClearHighlight}
          activeColor={states.currentHighlight}
        />
        <MemoColorList
          changeColorCommand={commands.onChangeColor}
          clearColorCommand={commands.onClearColor}
          activeColor={states.currentColor}
        />
        <Popover.Root>
          <Popover.Trigger asChild>
            <MemoButton tooltip={t('more-options')}>
              <FontAwesomeIcon icon={faEllipsisV} />
            </MemoButton>
          </Popover.Trigger>
          <Popover.Content
            side="top"
            asChild
          >
            <Toolbar.Wrapper>
              <MemoButton
                tooltip={t('subscript')}
                tooltipShortcut={['Mod', '.']}
                onClick={commands.onSubscript}
                active={states.isSubscript}
              >
                <FontAwesomeIcon icon={faSubscript} />
              </MemoButton>
              <MemoButton
                tooltip={t('superscript')}
                tooltipShortcut={['Mod', ',']}
                onClick={commands.onSuperscript}
                active={states.isSuperscript}
              >
                <FontAwesomeIcon icon={faSuperscript} />
              </MemoButton>
              <Toolbar.Divider />
              <MemoButton
                tooltip={t('align-left')}
                tooltipShortcut={['Shift', 'Mod', 'L']}
                onClick={commands.onAlignLeft}
                active={states.isAlignLeft}
              >
                <FontAwesomeIcon icon={faAlignLeft} />
              </MemoButton>
              <MemoButton
                tooltip={t('align-center')}
                tooltipShortcut={['Shift', 'Mod', 'E']}
                onClick={commands.onAlignCenter}
                active={states.isAlignCenter}
              >
                <FontAwesomeIcon icon={faAlignCenter} />
              </MemoButton>
              <MemoButton
                tooltip={t('align-right')}
                tooltipShortcut={['Shift', 'Mod', 'R']}
                onClick={commands.onAlignRight}
                active={states.isAlignRight}
              >
                <FontAwesomeIcon icon={faAlignRight} />
              </MemoButton>
              <MemoButton
                tooltip={t('align-justify')}
                tooltipShortcut={['Shift', 'Mod', 'J']}
                onClick={commands.onAlignJustify}
                active={states.isAlignJustify}
              >
                <FontAwesomeIcon icon={faAlignJustify} />
              </MemoButton>
            </Toolbar.Wrapper>
          </Popover.Content>
        </Popover.Root>
      </Toolbar.Wrapper>
    </BubbleMenu>
  );
};
