import {
  graphql,
  QueryAdjustToneArgs,
  QueryFixGrammarAndSpellingArgs,
  QueryLengthenTextArgs,
  QueryShortenTextArgs,
  QuerySimplifyTextArgs,
} from '@stellar-lms-frontend/lms-graphql';
import { graphQLClient } from '../../../lib/graphql';

export const aiSimplify = (variables: QuerySimplifyTextArgs) => {
  return graphQLClient('designer')
    .request(
      graphql(`
        query aiSimplify($courseId: ID!, $text: String!) {
          simplifyText(courseId: $courseId, text: $text)
        }
      `),
      variables,
    )
    .then((response) => response.simplifyText);
};

export const aiShorten = (variables: QueryShortenTextArgs) => {
  return graphQLClient('designer')
    .request(
      graphql(`
        query aiShorten($courseId: ID!, $text: String!) {
          shortenText(courseId: $courseId, text: $text)
        }
      `),
      variables,
    )
    .then((response) => response.shortenText);
};
export const aiLengthen = (variables: QueryLengthenTextArgs) => {
  return graphQLClient('designer')
    .request(
      graphql(`
        query aiLengthen($courseId: ID!, $text: String!) {
          lengthenText(courseId: $courseId, text: $text)
        }
      `),
      variables,
    )
    .then((response) => response.lengthenText);
};
export const aiFixGrammarAndSpelling = (variables: QueryFixGrammarAndSpellingArgs) => {
  return graphQLClient('designer')
    .request(
      graphql(`
        query aiFixGrammarAndSpelling($courseId: ID!, $text: String!) {
          fixGrammarAndSpelling(courseId: $courseId, text: $text)
        }
      `),
      variables,
    )
    .then((response) => response.fixGrammarAndSpelling);
};
export const aiAdjustTone = (variables: QueryAdjustToneArgs) => {
  return graphQLClient('designer')
    .request(
      graphql(`
        query aiAdjustTone($courseId: ID!, $text: String!, $tone: String!) {
          adjustTone(courseId: $courseId, text: $text, tone: $tone)
        }
      `),
      variables,
    )
    .then((response) => response.adjustTone);
};
